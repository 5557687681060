import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { Injectable } from '@angular/core';
import { LogType } from '../state/log-type';
import { LogsCollectionService } from '../state/logs-collection.service';
import { Logger } from './logger';

@Injectable()
export class DebugLogger extends Logger {

  private start: dayjs.Dayjs;

  constructor(
    private logsCollectionService: LogsCollectionService,
  ) {
    super();
    this.start = dayjs.utc();
  }

  async log(...args: any[]): Promise<void> {
    const data = this.convertToStrings(args);
    this.logsCollectionService.addLogEntry({
      id: nanoid(),
      timestamp: dayjs.utc().diff(this.start, 'seconds', true),
      data,
      type: LogType.log,
    });
  }

  async warn(...args: any[]): Promise<void> {
    const data = this.convertToStrings(args);
    this.logsCollectionService.addLogEntry({
      id: nanoid(),
      timestamp: dayjs.utc().diff(this.start, 'seconds', true),
      data,
      type: LogType.warn,
    });
  }

  async error(...args: any[]): Promise<void> {
    const data = this.convertToStrings(args);
    this.logsCollectionService.addLogEntry({
      id: nanoid(),
      timestamp: dayjs.utc().diff(this.start, 'seconds', true),
      data,
      type: LogType.error,
    });
  }

}
