import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ReauthenticateComponent } from './components/reauthenticate/reauthenticate.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SafeAdminOnlyGuard } from './guards/fireco-admin-only.guard';
import { RedirectIfSignedInGuard } from './guards/redirect-if-signed-in.guard';
import { RedirectIfSignedOutGuard } from './guards/redirect-if-signed-out.guard';
import { AuthService } from './services/auth.service';
import { AuthQuery } from './state/auth.query';
import { AuthState } from './state/auth.state';
import { AuthStore } from './state/auth.store';

@NgModule({
  declarations: [
    ReauthenticateComponent,
    SignInComponent,
  ],
  exports: [
    ReauthenticateComponent,
    SignInComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    AngularFireAuthModule,
  ],
})
export class AuthLibModule {

  static forRoot(): ModuleWithProviders<AuthLibModule> {
    return {
      ngModule: AuthLibModule,
      providers: [
        AuthService,
        AuthQuery,
        AuthState,
        AuthStore,
        SafeAdminOnlyGuard,
        RedirectIfSignedInGuard,
        RedirectIfSignedOutGuard,
      ]
    };
  };

}
