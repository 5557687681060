import { Inject, Injectable } from '@angular/core';
import { LOGGER, Logger } from './logger';

@Injectable()
export class LoggingService {

  constructor(
    @Inject(LOGGER) private loggers: Logger[],
  ) { }

  async log(...args: any[]): Promise<void> {
    this.loggers.forEach((logger) => logger.log(...args));
  }

  async warn(...args: any[]): Promise<void> {
    this.loggers.forEach((logger) => logger.warn(...args));
  }

  async error(...args: any[]): Promise<void> {
    this.loggers.forEach((logger) => logger.error(...args));
  }

}
