import { Injectable } from '@angular/core';
import { Logger } from './logger';

@Injectable()
export class ConsoleLogger extends Logger {

  async log(...args: any[]): Promise<void> {
    this.convertToStrings(args).forEach((msg) => console.log(new Date().toISOString(), msg));
  }

  async warn(...args: any[]): Promise<void> {
    this.convertToStrings(args).forEach((msg) => console.warn(new Date().toISOString(), msg));
  }

  async error(...args: any[]): Promise<void> {
    this.convertToStrings(args).forEach((msg) => console.error(new Date().toISOString(), msg));
  }

}
