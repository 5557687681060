<form>

  <ng-content select="[title]"></ng-content>

  <ng-content select="[subtitle]"></ng-content>

  <div *ngIf="enablePassword" class="m-t-16">
    <ion-item fill="outline" mode="md" class="safe-input">
      <ion-input autocomplete="current-password" enterkeyhint="go" inputmode="text"
        placeholder="Password" required="true" type="password"
        [formControl]="passwordControl" (keyup.enter)="reauthenticateWithPassword()"
        (ionBlur)="checkForPasswordErrors()" (ionFocus)="resetPasswordErrors()"></ion-input>
      <span slot="error" *ngIf="showPasswordRequiredError">Please enter your password.</span>
    </ion-item>
  </div>

  <div *ngIf="enablePassword" class="m-t-16 ion-text-center">
    <ion-button *ngIf="!processingReauthenticateWithPassword; else spinner;" class="reset-text-transform button-text"
      type="submit" [disabled]="passwordControl.invalid"
      expand="block" size="large" (click)="reauthenticateWithPassword()">
      <ng-content select="[reauthenticateButtonText]"></ng-content>
    </ion-button>
  </div>

  <div *ngIf="enablePassword && enableGoogle" class="ion-text-center m-t-16">
    <ion-text color="secondary">Or</ion-text>
  </div>

  <div *ngIf="enableGoogle" class="m-t-16 ion-text-center">
    <ion-button *ngIf="!processingReauthenticateWithGoogle; else spinner;" class="reset-text-transform button-text" color="secondary"
      type="button" expand="block" fill="outline" size="large" (click)="reauthenticateWithGoogle()">
      <span slot="start" class="google-logo reauthenticate__google-logo"></span>
      <ion-text class="p-l-12">Reauthenticate with Google</ion-text>
    </ion-button>
  </div>

  <ng-template #spinner>
    <ion-spinner class="safe-button-spinner" color="primary"></ion-spinner>
  </ng-template>

  <div *ngIf="error" class="ion-text-center m-t-16">
    <ion-text color="danger">{{error}}</ion-text>
  </div>

  <div *ngIf="success" class="ion-text-center m-t-16">
    <ion-text color="success">{{success}}</ion-text>
  </div>

</form>
