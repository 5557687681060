import { Subject } from 'rxjs';
import { bufferTime, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { ToastController } from '@ionic/angular';
import { Logger } from './logger';

@Injectable()
export class ToastLogger extends Logger {

  private toast: HTMLIonToastElement | null = null;
  private showing = Promise.resolve();

  private logsSubject = new Subject<string>();
  private bufferedLogs = this.logsSubject.pipe(
    bufferTime(1000),
    filter(logsBatch => logsBatch && logsBatch.length > 0),
  );

  constructor(
    private toastController: ToastController,
  ) {
    super();
    this.bufferedLogs.subscribe({
      next: (logsBatch) => {
        this.showing = this.showing.then(() => this.showToast(logsBatch, 'tertiary', 'Logs'));
      }
    });
  }

  async log(...args: any[]): Promise<void> {
    args.forEach(arg => this.logsSubject.next(`I: ${arg}`));
  }

  async warn(...args: any[]): Promise<void> {
    args.forEach(arg => this.logsSubject.next(`W: ${arg}`));
  }

  async error(...args: any[]): Promise<void> {
    args.forEach(arg => this.logsSubject.next(`E: ${arg}`));
  }

  async showToast(args: any[], color: string, header: string): Promise<void> {
    if (this.toast) {
      await this.toast.dismiss();
    }

    const appState = await App.getState();
    if (appState.isActive) {
      const messages = this.convertToStrings(args).reverse();

      this.toast = await this.toastController.create({
        color,
        duration: 5000,
        header,
        message: messages.join('\n'),
        buttons: ['X'],
      });

      this.toast.onDidDismiss().then(() => {
        this.toast = null;
      });

      await this.toast.present();
    }
  }

}
