import firebase from 'firebase/app';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoggingService } from '@safe/logging-lib';
import { AuthQuery } from './auth.query';
import { AuthStore, createInitialAuthModel } from './auth.store';

@Injectable()
export class AuthState {

  authState$ = this.query.select();
  availableProviders$ = this.query.select('availableProviders');
  currentProvider$ = this.query.select('currentProvider');
  email$ = this.query.select('email');
  isNewUser$ = this.query.select('isNewUser');
  isSafeAdmin$ = this.query.select('isSafeAdmin');
  isSignedIn$ = this.email$.pipe(map((email) => !!email));
  passwordHash$ = this.query.select('passwordHash');
  reauthenticated$ = this.query.select('reauthenticated');
  uid$ = this.query.select('uid');

  get availableProviders() {
    return this.query.getValue().availableProviders;
  }
  get currentProvider() {
    return this.query.getValue().currentProvider;
  }
  get email() {
    return this.query.getValue().email;
  }
  get isNewUser() {
    return this.query.getValue().isNewUser;
  }
  get isSafeAdmin() {
    return this.query.getValue().isSafeAdmin;
  }
  get isSignedIn() {
    return !!this.email;
  }
  get passwordHash() {
    return this.query.getValue().passwordHash;
  }
  get reauthenticated() {
    return this.query.getValue().reauthenticated;
  }
  get uid() {
    return this.query.getValue().uid;
  }

  constructor(
    private loggingService: LoggingService,
    private query: AuthQuery,
    private store: AuthStore,
  ) { }

  setStateFromCredential(userCredential: firebase.auth.UserCredential | null): void {
    if (userCredential) {
      const email = userCredential.user ? userCredential.user.email : null;
      const isNewUser = userCredential.additionalUserInfo ? userCredential.additionalUserInfo.isNewUser : null;
      const uid = userCredential.user ? userCredential.user.uid : null;

      const currentProvider = userCredential.credential ? userCredential.credential.signInMethod :
        (userCredential.additionalUserInfo ? userCredential.additionalUserInfo.providerId :
          (userCredential.user ? userCredential.user.providerId : null));

      const availableProviders: string[] = [];
      if (userCredential.user) {
        userCredential.user.providerData.forEach(p => {
          if (p && p.providerId) {
            availableProviders.push(p.providerId);
          }
        });
      }

      this.store.update({
        availableProviders,
        currentProvider,
        email,
        isNewUser,
        uid,
      });

      if (userCredential.user) {
        this.updateSafeAdmin(userCredential.user);
      }
    } else {
      this.store.update(createInitialAuthModel());
    }
  }

  setStateFromUser(user: firebase.User | null): void {
    this.loggingService.log(`Setting state from user. Has user: ${!!user}`);
    if (user) {
      this.store.update({
        email: user.email,
        uid: user.uid,
      });

      const availableProviders: string[] = [];
      user.providerData.forEach(p => {
        if (p && p.providerId) {
          availableProviders.push(p.providerId);
        }
      });
      this.store.update({
        availableProviders,
      });

      this.updateSafeAdmin(user);
    } else {
      this.store.update(createInitialAuthModel());
    }
  }

  setPasswordHash(hash: string): void {
    this.store.update({ passwordHash: hash, });
  }

  clearPasswordHash(): void {
    this.store.update({ passwordHash: null, });
  }

  setReauthenticated(reauthenticated: boolean): void {
    this.store.update({ reauthenticated, });
  }

  private updateSafeAdmin(user: firebase.User): void {
    user.getIdTokenResult().then((idTokenResult) => {
      const claims = idTokenResult.claims;
      const isSafeAdmin = claims.safeAdmin || false;
      this.store.update({
        isSafeAdmin,
      });
    });
  }

}
