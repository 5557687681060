import { Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@safe/auth-lib';
import { AppRoutes } from '../enums/app-routes';

@Injectable({ providedIn: 'root' })
export class NavigationService implements OnDestroy {

  private navigationInitialised = false;
  private userSub: Subscription | undefined;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  initialise(): void {
    this.userSub = this.authService.isSignedIn$.subscribe({
      next: isSignedIn => {
        if (this.navigationInitialised && !isSignedIn) {
          this.navigateTo(AppRoutes.Auth);
        }
      }
    });
    this.router.initialNavigation();
    this.navigationInitialised = true;
  }

  ngOnDestroy(): void {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  navigateTo(route: AppRoutes, replaceUrl = false): void {
    this.router.navigateByUrl(route, { replaceUrl, });
  }

}
