import { InjectionToken } from '@angular/core';

export const LOGGER = new InjectionToken<Logger>('app.logger');


export abstract class Logger {

  protected convertToString(arg: any): string {
    if (arg === null) {
      return 'null';
    }
    if (arg === undefined) {
      return 'undefined';
    }
    if (typeof arg === 'string') {
      return arg;
    }
    if (typeof arg === 'boolean') {
      return '' + arg;
    }
    if (typeof arg === 'number') {
      return '' + arg;
    }
    if (typeof arg === 'bigint') {
      return arg.toString();
    }
    if (typeof arg === 'symbol') {
      return arg.toString();
    }
    if (typeof arg === 'function') {
      return arg.toString();
    }
    if (typeof arg === 'object') {
      try {
        return JSON.stringify(arg);
      } catch (error) {
        return arg.toString();
      }
    }
    return '';
  }

  protected convertToStrings(args: any[]): string[] {
    return args.map((arg) => this.convertToString(arg));
  }

  abstract log(...args: any[]): Promise<void>;

  abstract warn(...args: any[]): Promise<void>;

  abstract error(...args: any[]): Promise<void>;

}
