import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'safe-logs-button',
  templateUrl: './logs-button.component.html',
  styleUrls: ['./logs-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogsButtonComponent {

  @Input() production = true;

}
