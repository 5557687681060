import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface AuthModel {
  availableProviders: string[];
  currentProvider: string | null;
  email: string | null;
  isNewUser: boolean | null;
  isSafeAdmin: boolean | null;
  passwordHash: string | null;
  reauthenticated: boolean | null;
  uid: string | null;
}

export const createInitialAuthModel = (): AuthModel => ({
  availableProviders: [],
  currentProvider: null,
  email: null,
  isNewUser: null,
  isSafeAdmin: null,
  passwordHash: null,
  reauthenticated: null,
  uid: null,
});

@Injectable()
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthModel> {
  constructor() {
    super(createInitialAuthModel());
  }
}
