
import { IsNotProductionGuard } from 'libs/logging-lib/src/lib/guards/is-not-production.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogsComponent } from './components/logs/logs.component';

const routes: Routes = [
  {
    path: 'logs',
    canActivate: [IsNotProductionGuard],
    component: LogsComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class LoggingRoutingModule { }
