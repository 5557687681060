export enum AppRoutes {
  Auth = '/auth',
  Home = '/home',
  AdminOrganisation = '/home/admin/organisation',
  AdminPeople = '/home/admin/people',
  AdminRiskAssessments = '/home/admin/risk-assessments',
  AdminSystemConfiguration = '/home/admin/system-configuration',
  AdminRolesAndAccess = '/home/admin/roles-and-access',
  DirectoryPersonalProfile = './home/directory/personal-profile',
  DirectoryEmploymentProfile = './home/directory/employment-profile',
  DirectoryHealthSafetyRisk = './home/directory/health-safety-risk',
  ActionsInitiateSafe = './home/actions/initiate-safe',
  ActionsPushNotifications = './home/actions/push-notifications',
  EventsDashboard = './home/events/dashboard',
  EventsPushNotifications = './home/events/push-notifications',
  EventsInitiateSafe = './home/events/initiate-safe',
  EventsAccidentsIncidents = './home/events/accidents-and-incidents',
  EventsFireRollCall = './home/events/fire-roll-call',
  EventsLoneWorker = './home/events/lone-worker',
  EventsRiskAssessments = './home/events/risk-assessments',
}
