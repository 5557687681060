import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LoggingService } from '@safe/logging-lib';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'safe-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent {

  @Output() signedIn = new EventEmitter<void>();

  emailControl = this.fb.control('', [Validators.required, Validators.email]);
  passwordControl = this.fb.control('', [Validators.required]);

  showEmailRequiredError = false;
  showValidEmailError = false;
  showPasswordRequiredError = false;

  processingSignInWithEmail = false;
  processingResetEmail = false;
  processingSignInWithGoogle = false;
  processingSignInWithApple = false;

  error: string | null = null;
  success: string | null = null;
  forgottenPassword = false;

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private loggingService: LoggingService,
  ) { }

  checkForEmailErrors(): void {
    this.showEmailRequiredError = this.emailControl.invalid && this.emailControl.touched && this.emailControl.errors && this.emailControl.errors.required;
    this.showValidEmailError = this.emailControl.invalid && this.emailControl.touched && this.emailControl.errors && this.emailControl.errors.email;
  }

  resetEmailErrors(): void {
    this.showEmailRequiredError = false;
    this.showValidEmailError = false;
  }

  checkForPasswordErrors(): void {
    this.showPasswordRequiredError = this.passwordControl.invalid && this.passwordControl.touched && this.passwordControl.errors && this.passwordControl.errors.required;
  }

  resetPasswordErrors(): void {
    this.showPasswordRequiredError = false;
  }

  showPasswordReset(): void {
    this.forgottenPassword = true;
    this.error = null;
    this.success = null;
  }

  showSignIn(): void {
    this.forgottenPassword = false;
    this.error = null;
    this.success = null;
  }

  async signIn(): Promise<void> {
    if (this.emailControl.invalid || this.passwordControl.invalid) {
      return;
    }
    this.loggingService.log('Signing in...');

    this.error = null;
    this.processingSignInWithEmail = true;
    this.cdr.markForCheck();

    const email = this.emailControl.value;
    const password = this.passwordControl.value;

    try {
      await this.authService.signIn(email, password);
      this.signedIn.emit();
      this.loggingService.log('Sign in successful.');
    } catch (error: any) {
      this.loggingService.warn(`Sign in unsuccessful. ${error.message}`);
      this.error = error.message;
    } finally {
      this.processingSignInWithEmail = false;
      this.cdr.markForCheck();
    }
  }

  async sendPasswordResetEmail(): Promise<void> {
    if (this.emailControl.invalid) {
      return;
    }
    this.loggingService.log('Sending password reset email...');

    this.error = null;
    this.success = null;
    this.processingResetEmail = true;
    this.cdr.markForCheck();

    const email = this.emailControl.value;
    try {
      await this.authService.sendPasswordResetEmail(email);
      this.loggingService.log('Password reset email sent successfully.');
      this.success = `An email has been sent to ${email}. Please follow the instructions in the email to reset your password.`;
    } catch (error: any) {
      this.loggingService.warn(`Sending password reset email unsuccessful. ${error.message}`);
      this.error = error.message;
    } finally {
      this.processingResetEmail = false;
      this.cdr.markForCheck();
    }
  }

  async signInWithGoogle(): Promise<void> {
    this.loggingService.log(`Signing in with Google...`);

    this.error = null;
    this.success = null;
    this.processingSignInWithGoogle = true;
    this.cdr.markForCheck();

    try {
      await this.authService.signInWithGoogle();
      this.signedIn.emit();
      this.loggingService.log('Signed in with Google.');
      this.success = `Sign in with Google successful.`;
    } catch (error: any) {
      this.loggingService.warn(`Sign in with Google unsuccessful. ${error.message}`);
      this.error = error.message;
    } finally {
      this.processingSignInWithGoogle = false;
      this.cdr.markForCheck();
    }
  }

  async signInWithApple(): Promise<void> {
    this.loggingService.log(`Signing in with Apple...`);

    this.error = null;
    this.success = null;
    this.processingSignInWithApple = true;
    this.cdr.markForCheck();

    try {
      await this.authService.signInWithApple();
      this.signedIn.emit();
      this.loggingService.log('Signed in with Apple.');
      this.success = `Sign in with Apple successful.`;
    } catch (error: any) {
      this.loggingService.warn(`Sign in with Apple unsuccessful. ${error.message}`);
      this.error = error.message;
    } finally {
      this.processingSignInWithApple = false;
      this.cdr.markForCheck();
    }
  }

}
