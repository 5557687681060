import { environment } from 'apps/dashboard/src/environments/environment';
import firebase from 'firebase/app';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import {
  AuthLibModule, REDIRECT_IF_SIGNED_IN_ROUTE, REDIRECT_IF_SIGNED_OUT_ROUTE, RESET_PASSWORD_SETTINGS,
} from '@safe/auth-lib';
import { LoggingLibModule } from '@safe/logging-lib';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppRoutes } from './shared/enums/app-routes';

const resetPasswordSettings: firebase.auth.ActionCodeSettings = {
  url: `${environment.baseUrl}/auth`,
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  entryComponents: [

  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: 'md', }),
    LoggingLibModule.forRoot(environment.loggingConfig, environment.production),
    AppRoutingModule,
    AkitaNgRouterStoreModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AngularFireModule.initializeApp(environment.fbConfig),
    AuthLibModule.forRoot(),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: REDIRECT_IF_SIGNED_IN_ROUTE, useValue: AppRoutes.Home },
    { provide: REDIRECT_IF_SIGNED_OUT_ROUTE, useValue: AppRoutes.Auth },
    { provide: RESET_PASSWORD_SETTINGS, useValue: resetPasswordSettings },
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule { }
