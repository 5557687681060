import firebase from 'firebase/app';
import md5 from 'md5';
import { BehaviorSubject, Observable } from 'rxjs';
import { Inject, Injectable, InjectionToken, OnDestroy, Optional } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Unsubscribe } from '@firebase/util';
import { Platform } from '@ionic/angular';
import { LoggingService } from '@safe/logging-lib';
import { AuthState } from '../state/auth.state';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';

export const RESET_PASSWORD_SETTINGS = new InjectionToken<firebase.auth.ActionCodeSettings>('auth.resetPasswordSettings');
export const CREATE_USER_IF_DOESNT_EXIST = new InjectionToken<boolean>('auth.createUserIfDoesntExist');
export const CAN_DELETE_USERS = new InjectionToken<boolean>('auth.canDeletUsers');

@Injectable()
export class AuthService implements OnDestroy {

  authState$ = this.authState.authState$;
  availableProviders$ = this.authState.availableProviders$;
  currentProvider$ = this.authState.currentProvider$;
  email$ = this.authState.email$;
  isNewUser$ = this.authState.isNewUser$;
  isSafeAdmin$ = this.authState.isSafeAdmin$;
  isSignedIn$ = this.authState.isSignedIn$;
  passwordHash$ = this.authState.passwordHash$;
  reauthenticated$ = this.authState.reauthenticated$;
  uid$ = this.authState.uid$;

  get availableProviders() {
    return this.authState.availableProviders;
  }
  get currentProvider() {
    return this.authState.currentProvider;
  }
  get email() {
    return this.authState.email;
  }
  get isNewUser() {
    return this.authState.isNewUser;
  }
  get isSafeAdmin() {
    return this.authState.isSafeAdmin;
  }
  get isSignedIn() {
    return this.authState.isSignedIn;
  }
  get passwordHash() {
    return this.authState.passwordHash;
  }
  get reauthenticated() {
    return this.authState.reauthenticated;
  }
  get uid() {
    return this.authState.uid;
  }

  private resetPasswordSettings: firebase.auth.ActionCodeSettings | undefined = {
    url: 'https://dev-firecosafe-admin.web.app/auth',
  };
  private createUserIfDoesntExist = false;
  private canDeleteUsers = false;

  private unsubscribeFromAuthStatChanges: Unsubscribe | undefined;
  private initialised = false;
  private authInitialised = new BehaviorSubject<boolean>(false);

  constructor(
    private auth: AngularFireAuth,
    private authState: AuthState,
    private loggingService: LoggingService,
    private platform: Platform,
    @Optional() @Inject(RESET_PASSWORD_SETTINGS) resetPasswordSettings: firebase.auth.ActionCodeSettings,
    @Optional() @Inject(CREATE_USER_IF_DOESNT_EXIST) createUserIfDoesntExist: boolean,
    @Optional() @Inject(CAN_DELETE_USERS) canDeleteUsers: boolean,
  ) {
    if (resetPasswordSettings) {
      this.resetPasswordSettings = resetPasswordSettings;
    }
    if (createUserIfDoesntExist) {
      this.createUserIfDoesntExist = createUserIfDoesntExist;
    }
    if (canDeleteUsers) {
      this.canDeleteUsers = canDeleteUsers;
    }
  }

  async initialise(): Promise<Observable<boolean>> {
    this.unsubscribeFromAuthStatChanges = await this.auth.onAuthStateChanged({
      next: (user) => {
        this.authState.setStateFromUser(user);
        this.loggingService.log(`Auth State: ${user ? 'User Signed In.' : 'No User.'}`);

        if (!this.initialised) {
          this.loggingService.log('Auth Service initialised...');
          this.initialised = true;
          this.authInitialised.next(true);
        }
      },
      error: (error) => {
        this.loggingService.error(`Error from Auth State listener:`, error);
      },
      complete: () => {
        this.loggingService.warn(`Auth State Listener completed!`);
      },
    });

    if (!this.platform.is('ios')) {
      try {
        const userCredential = await this.auth.getRedirectResult();
        if (userCredential && userCredential.operationType === 'signIn') {
          this.authState.setStateFromCredential(userCredential);
          FirebaseAnalytics.logEvent({
            name: 'login',
            params: {
              method: `${userCredential.additionalUserInfo?.providerId === 'apple.com' ? 'Apple' : 'Google'} - Web Redirect`,
            }
          });
          this.loggingService.log(`User received from sign in redirect result (${userCredential.user?.email})`);
        }
        if (userCredential && userCredential.operationType === 'reauthenticate') {
          this.authState.setStateFromCredential(userCredential);
          this.loggingService.log(`User received from reauthenticate redirect result (${userCredential.user?.email})`);
          this.authState.setReauthenticated(Boolean(userCredential.user));
        }
      } catch (error: any) {
        this.loggingService.error('Error while retrieving redirect result:', error.toString());
      }
    }

    return this.authInitialised.asObservable();
  }

  ngOnDestroy(): void {
    if (this.unsubscribeFromAuthStatChanges) {
      this.unsubscribeFromAuthStatChanges();
      this.unsubscribeFromAuthStatChanges = undefined;
    }
  }

  setPasswordResetSettings(settings: firebase.auth.ActionCodeSettings): void {
    this.resetPasswordSettings = settings;
  }

  async signIn(email: string, password: string): Promise<void> {
    try {
      const userCredential = await this.auth.signInWithEmailAndPassword(email, password);
      this.authState.setStateFromCredential(userCredential);
      FirebaseAnalytics.logEvent({
        name: 'login',
        params: {
          method: 'Password',
        }
      });
      return;
    } catch (error: any) {
      const signInMethodsForThisEmail = await this.auth.fetchSignInMethodsForEmail(email);
      if (signInMethodsForThisEmail.includes('google.com') && !signInMethodsForThisEmail.includes('password')) {
        this.loggingService.error('Google sign in account trying to use password sign in.');
        throw new Error('This email was previously used with Google sign in. Either use Google sign in again, or use the forgotten password link to set your password.');
      }
      if (error.code !== 'auth/user-not-found' || !this.createUserIfDoesntExist) {
        this.loggingService.error(`Error while signing in:`, error.toString());
        throw new Error('We were unable to sign you in. Please check your email and password.');
      }
    }

    if (this.createUserIfDoesntExist) {
      this.loggingService.log('User not found. Creating new user...');
      this.authState.setPasswordHash(md5(password));
      try {
        const userCredential = await this.auth.createUserWithEmailAndPassword(email, password);
        this.authState.setStateFromCredential(userCredential);
        userCredential.user?.sendEmailVerification(this.resetPasswordSettings);
      } catch (error: any) {
        this.authState.clearPasswordHash();
        this.loggingService.error(`Error while creating user:`, error.toString());
        throw new Error('We were unable to sign you up. Please check your email and password.');
      }
    }
  }

  async signInWithGoogle(): Promise<void> {
    if (this.platform.is('ios') || this.platform.is('android')) {
      await this.signInWithGoogleForNative();
    } else {
      await this.signInWithGoogleForWeb();
    }
  }

  private async signInWithGoogleForNative(): Promise<void> {
    try {
      const user = await GoogleAuth.signIn();
      this.loggingService.log(`Native Google Auth - Google User received with email ${user.email}`);
      const provider = firebase.auth.GoogleAuthProvider.credential(user.authentication.idToken);
      const userCredential = await this.auth.signInWithCredential(provider);
      this.loggingService.log(`Native Google Auth - Firebase credential returned - ${userCredential.user?.email}`);
      this.authState.setStateFromCredential(userCredential);
      FirebaseAnalytics.logEvent({
        name: 'login',
        params: {
          method: 'Google - Native',
        }
      });
    } catch (error: any) {
      this.loggingService.error(`Error while signing in with Google:`, error.toString());
      throw new Error('We were unable to sign you in with Google.');
    }
  }

  private async signInWithGoogleForWeb(): Promise<void> {
    try {
      await this.auth.signInWithRedirect(new firebase.auth.GoogleAuthProvider());
    } catch (error: any) {
      this.loggingService.error(`Error while signing in with Google:`, error.toString());
      throw new Error('We were unable to sign you in with Google.');
    }
  }

  async signInWithApple(): Promise<void> {
    // if (this.platform.is('ios')) {
      await this.signInWithAppleForIos();
    // } else {
    //   await this.signInWithAppleForWebOrAndroid();
    // }
  }

  private async signInWithAppleForIos(): Promise<void> {
    try {
      const response = await SignInWithApple.authorize({
        clientId: 'com.firecosafe',
        redirectURI: 'https://dev.firecosafe.com/auth',
        scopes: 'email name',
      });
      this.loggingService.log(`Native Apple Auth - Apple Response received with email ${response.response.email}`);
      const provider = new firebase.auth.OAuthProvider('apple.com');
      const credential = provider.credential({ idToken: response.response.identityToken, });
      const userCredential = await this.auth.signInWithCredential(credential);
      this.loggingService.log(`Native Apple Auth - Firebase credential returned - ${userCredential.user?.email}`);
      this.authState.setStateFromCredential(userCredential);
      FirebaseAnalytics.logEvent({
        name: 'login',
        params: {
          method: 'Apple - Native',
        }
      });
    } catch (error: any) {
      this.loggingService.error(`Error while signing in with Apple:`, error.toString());
      throw new Error('We were unable to sign you in with Apple.');
    }
  }

  private async signInWithAppleForWebOrAndroid(): Promise<void> {
    try {
      const provider = new firebase.auth.OAuthProvider('apple.com');
      provider.addScope('name');
      provider.addScope('email');
      await this.auth.signInWithRedirect(provider);
    } catch (error: any) {
      this.loggingService.error(`Error while signing in with Apple:`, error.toString());
      throw new Error('We were unable to sign you in with Apple.');
    }
  }

  async signOut(): Promise<void> {
    await this.auth.signOut();
  }

  async sendPasswordResetEmail(email: string): Promise<void> {
    try {
      await this.auth.sendPasswordResetEmail(email, this.resetPasswordSettings);
    } catch (error: any) {
      this.loggingService.error(`Error while sending password reset email:`, error.toString());
      throw new Error('There was an error while sending your password reset email.');
    }
  }

  async deleteUser(): Promise<void> {
    if (!this.canDeleteUsers) {
      throw new Error('Unsupported operation.');
    }

    try {
      const user = await this.auth.currentUser;
      if (user) {
        await user.delete();
      }
    } catch (error: any) {
      this.loggingService.error('Error while deleting account:', error.toString());
      throw new Error('Unable to delete account.');
    }
  }

  clearPasswordHash(): void {
    this.authState.clearPasswordHash();
  }

  async reauthenticateWithPassword(password: string): Promise<boolean> {
    try {
      const currentUser = await this.auth.currentUser;
      if (!currentUser || !currentUser.email) {
        throw new Error('No user to reauthenticate.');
      }

      const credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, password);
      const userCredential = await currentUser.reauthenticateWithCredential(credential);
      return Boolean(userCredential.user);
    } catch (error: any) {
      this.loggingService.error('Reauthenticate with password failed with error:', error);
      throw new Error('Reauthenticate failed.');
    }
  }

  async reauthenticateWithGoogle(): Promise<boolean | void> {
    if (this.platform.is('ios') || this.platform.is('android')) {
      return this.reauthenticateWithGoogleForNative();
    } else {
      return this.reauthenticateWithGoogleForWeb();
    }
  }

  private async reauthenticateWithGoogleForNative(): Promise<boolean> {
    try {
      const currentUser = await this.auth.currentUser;
      if (!currentUser || !currentUser.email) {
        throw new Error('No user to reauthenticate.');
      }

      const user = await GoogleAuth.signIn();
      this.loggingService.log(`Native Google Reauth - Google User received with email ${user.email}`);
      const credential = firebase.auth.GoogleAuthProvider.credential(user.authentication.idToken);
      const userCredential = await currentUser.reauthenticateWithCredential(credential);
      this.loggingService.log(`Native Google Reauth - Firebase credential returned`);
      return Boolean(userCredential.user);
    } catch (error: any) {
      this.loggingService.error(`Error while reauthenticating with Google (native):`, error.toString());
      throw new Error('We were unable to reauthenticate you with Google.');
    }
  }

  private async reauthenticateWithGoogleForWeb(): Promise<void> {
    try {
      const currentUser = await this.auth.currentUser;
      if (!currentUser || !currentUser.email) {
        throw new Error('No user to reauthenticate.');
      }

      await currentUser.reauthenticateWithRedirect(new firebase.auth.GoogleAuthProvider());
    } catch (error: any) {
      this.loggingService.error(`Error while reauthenticating with Google (web):`, error.toString());
      throw new Error('We were unable to reauthenticate you with Google.');
    }

  }

}
