<div class="ion-page">
  <ion-grid class="w-100 h-100 flex flex-column">

    <ion-row class="ion-align-items-center">
      <ion-col size="auto">
        <ion-back-button defaultHref="/"></ion-back-button>
      </ion-col>
      <ion-col size="auto">
        <ion-text color="secondary">
          <h2>Logs</h2>
        </ion-text>
      </ion-col>
    </ion-row>

    <ion-row class="scroll-y">
      <ion-col class="w-100">
        <ion-grid>
          <ion-row *ngFor="let log of (logs$ | async)">
            <ion-col size="2" class="ion-text-end">
              <ion-text [color]="log.type === type.error ? 'danger' : log.type === type.warn ? 'warning' : 'success'">
                <span>{{log.timestamp | number: '1.2-2'}}</span>
              </ion-text>
            </ion-col>
            <ion-col size="10">
              <ion-label *ngFor="let line of log.data" [color]="log.type === type.error ? 'danger' : log.type === type.warn ? 'warning' : 'success'">{{line}}<br/></ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
    </ion-row>

  </ion-grid>
</div>
