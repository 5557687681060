import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { LogType } from '../../state/log-type';
import { LogsCollectionService } from '../../state/logs-collection.service';

@Component({
  selector: 'safe-logs',
  templateUrl: './logs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogsComponent {

  logs$ = this.logsCollectionService.logs$;
  type = LogType;

  constructor(
    private logsCollectionService: LogsCollectionService,
    private router: Router,
  ) { }

  dismiss(): void {
    this.router.navigateByUrl('/');
  }

}
