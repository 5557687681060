import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { LogEntry } from './log-entry';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LogsState extends EntityState<LogEntry, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'logs', resettable: false, })
export class LogsStore extends EntityStore<LogsState> {
  constructor() {
    super();
  }
}
