import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LogsButtonComponent } from './components/logs-button/logs-button.component';
import { LogsComponent } from './components/logs/logs.component';
import { IsNotProductionGuard } from './guards/is-not-production.guard';
import { LoggingRoutingModule } from './logging-routing.module';
import { BlankLogger } from './services/blank-logger';
import { ConsoleLogger } from './services/console-logger';
import { DebugLogger } from './services/debug-logger';
import { LOGGER } from './services/logger';
import { LoggingService } from './services/logging.service';
import { ToastLogger } from './services/toast-logger';

dayjs.extend(utc);

export interface LoggingConfig {
  logToDebug: boolean;
  logToConsole: boolean;
  logToToast: boolean;
}

export const IS_PRODUCTION = new InjectionToken<boolean>('app.isProduction');

@NgModule({
  declarations: [
    LogsButtonComponent,
    LogsComponent,
  ],
  exports: [
    LogsButtonComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    LoggingRoutingModule,
  ],
})
export class LoggingLibModule {

  static forRoot(config: LoggingConfig, isProduction: boolean): ModuleWithProviders<LoggingLibModule> {
    return {
      ngModule: LoggingLibModule,
      providers: [
        { provide: IS_PRODUCTION, useValue: isProduction, },
        IsNotProductionGuard,
        !isProduction && config.logToDebug ?
          { provide: LOGGER, multi: true, useClass: DebugLogger, } :
          { provide: LOGGER, multi: true, useClass: BlankLogger, },
        !isProduction && config.logToConsole ?
          { provide: LOGGER, multi: true, useClass: ConsoleLogger, } :
          { provide: LOGGER, multi: true, useClass: BlankLogger, },
        !isProduction && config.logToToast ?
          { provide: LOGGER, multi: true, useClass: ToastLogger, } :
          { provide: LOGGER, multi: true, useClass: BlankLogger, },
        LoggingService,
      ]
    };
  }

}
