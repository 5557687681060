import { Injectable } from '@angular/core';
import { Logger } from './logger';

@Injectable()
export class BlankLogger extends Logger {

  async log(...args: any[]): Promise<void> {
    // intentionally blank.
  }

  async warn(...args: any[]): Promise<void> {
    // intentionally blank.
  }

  async error(...args: any[]): Promise<void> {
    // intentionally blank.
  }

}
