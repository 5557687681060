import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { LoggingService } from '@safe/logging-lib';
import { AuthService } from '../services/auth.service';

@Injectable()
export class SafeAdminOnlyGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private loggingService: LoggingService,
  ) { }

  canActivate(): Observable<boolean> {
    return this.authService.isSafeAdmin$.pipe(
      switchMap(async isSafeAdmin => {
        if (isSafeAdmin) {
          return true;
        } else {
          this.loggingService.warn(`Signing out as user is not a SAFE Admin.`);
          await this.authService.signOut();
          return false;
        }
      }),
    );
  }

}
