import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from '../environments/environment';
import { StartUpService } from './shared/services/start-up.service';

@Component({
  selector: 'safe-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  production = environment.production;

  constructor(
    private startUpService: StartUpService,
    private platform: Platform,
  ) { }

  ngOnInit(): void {
    this.startUpService.initialise();
  }

}
