import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { LogsState, LogsStore } from './logs.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy: 'timestamp', sortByOrder: Order.DESC, })
export class LogsQuery extends QueryEntity<LogsState> {
  constructor(protected store: LogsStore) {
    super(store);
  }
}
