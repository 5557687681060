import { Injectable } from '@angular/core';
import { LogEntry } from './log-entry';
import { LogsQuery } from './logs.query';
import { LogsStore } from './logs.store';

@Injectable({ providedIn: 'root' })
export class LogsCollectionService {

  logs$ = this.query.selectAll();

  constructor(
    private query: LogsQuery,
    private store: LogsStore,
  ) { }

  addLogEntry(logEntry: LogEntry): void {
    this.store.upsertMany([logEntry]);
  }

}
