import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AuthModel, AuthStore } from './auth.store';

@Injectable()
export class AuthQuery extends Query<AuthModel> {
  constructor(protected store: AuthStore) {
    super(store);
  }
}
