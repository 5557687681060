import { first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '@safe/auth-lib';
import { LoggingService } from '@safe/logging-lib';
import { NavigationService } from './navigation.service';

@Injectable({ providedIn: 'root' })
export class StartUpService {

  constructor(
    private authService: AuthService,
    private loggingService: LoggingService,
    private navigationService: NavigationService,
  ) { }

  async initialise(): Promise<void> {
    this.loggingService.log('Initialising app...');

    const authInitialised = await this.authService.initialise();
    authInitialised.pipe(
      first(init => init),
    ).subscribe(() => {
      this.navigationService.initialise();

      this.loggingService.log('App intialised.');

    });
  }

}
