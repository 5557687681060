// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { firebaseConfig } from '@safe/firebase-config-lib';

export const environment = {
  production: false,
  fbConfig: {
    ...firebaseConfig,
    appId: '1:785828139075:web:831fdaf28533bb10c404f6',
    apiKey: 'AIzaSyCvz2GwjLXzo7H6xEFa8CTrzVEmk5tCaGc',
    measurementId: 'G-ZQ04M4M8BB',
  },
  loggingConfig: {
    logToDebug: true,
    logToConsole: true,
    logToToast: false,
  },
  baseUrl: 'https://dev.firecosafe.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
