import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@safe/auth-lib';
import { LoggingService } from '@safe/logging-lib';

@Component({
  selector: 'safe-reauthenticate',
  templateUrl: './reauthenticate.component.html',
  styleUrls: ['./reauthenticate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReauthenticateComponent {

  @Input() enablePassword: boolean | null = false;
  @Input() enableGoogle: boolean | null = false;

  @Output() reauthenticated = new EventEmitter<boolean>();

  passwordControl = this.fb.control('', [Validators.required]);

  showPasswordRequiredError = false;

  processingReauthenticateWithPassword = false;
  processingReauthenticateWithGoogle = false;

  error: string | null = null;
  success: string | null = null;

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private loggingService: LoggingService,
  ) { }

  checkForPasswordErrors(): void {
    this.showPasswordRequiredError = this.passwordControl.invalid && this.passwordControl.touched && this.passwordControl.errors && this.passwordControl.errors.required;
  }

  resetPasswordErrors(): void {
    this.showPasswordRequiredError = false;
  }

  async reauthenticateWithPassword(): Promise<void> {
    if (this.passwordControl.invalid) {
      return;
    }
    this.loggingService.log('Reauthenticating with password...');

    this.error = null;
    this.processingReauthenticateWithPassword = true;
    this.cdr.markForCheck();

    const password = this.passwordControl.value;

    try {
      const result = await this.authService.reauthenticateWithPassword(password);
      this.reauthenticated.emit(result);
      this.loggingService.log(`Reauthenticated result - success = ${result}`);
      if (result) {
        this.success = `Reauthenticate with password successful.`;
      } else {
        this.error = `Reauthenticate with password unsuccessful.`;
      }
    } catch (error: any) {
      this.loggingService.warn(`Reauthenticate unsuccessful. ${error.message}`);
      this.error = error.message;
    } finally {
      this.processingReauthenticateWithPassword = false;
      this.cdr.markForCheck();
    }
  }

  async reauthenticateWithGoogle(): Promise<void> {
    this.loggingService.log(`Reauthenticating with Google...`);

    this.error = null;
    this.success = null;
    this.processingReauthenticateWithGoogle = true;
    this.cdr.markForCheck();

    try {
      const result = await this.authService.reauthenticateWithGoogle();
      if (typeof result === 'boolean') {
        this.reauthenticated.emit(result);
      }
      this.loggingService.log(`Reauthenticated with Google - success = ${result}`);
      if (result === true) {
        this.success = `Reauthenticate with Google successful.`;
      } else if (result === false) {
        this.error = `Reauthenticate with Google unsuccessful.`;
      }
    } catch (error: any) {
      this.loggingService.warn(`Reauthenticate with Google unsuccessful. ${error.message}`);
      this.error = error.message;
    } finally {
      this.processingReauthenticateWithGoogle = false;
      this.cdr.markForCheck();
    }
  }

}
