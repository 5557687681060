import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { IS_PRODUCTION } from '@safe/logging-lib';

@Injectable()
export class IsNotProductionGuard implements CanActivate {

  constructor(
    @Inject(IS_PRODUCTION) private isProduction: boolean,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return !this.isProduction;
  }

}
