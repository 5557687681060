import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@safe/auth-lib';
import { LoggingService } from '@safe/logging-lib';

export const REDIRECT_IF_SIGNED_IN_ROUTE = new InjectionToken<string>('auth.redirectIfSignedInRoute');

@Injectable()
export class RedirectIfSignedInGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private loggingService: LoggingService,
    @Optional() @Inject(REDIRECT_IF_SIGNED_IN_ROUTE) private redirectIfSignedInRoute: string,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.authService.isSignedIn$.pipe(
      map((isSignedIn) => {
        if (isSignedIn) {
          const route = this.redirectIfSignedInRoute || '/';
          this.loggingService.log(`Already signed in. Redirecting to ${route}.`);
          return this.router.parseUrl(route);
        } else {
          return true;
        }
      }),
    );
  }

}
