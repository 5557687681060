<form>

  <ng-content select="[title]"></ng-content>

  <ng-content select="[subtitle]" *ngIf="!forgottenPassword"></ng-content>
  <ng-content select="[subtitlePasswordReset]" *ngIf="forgottenPassword"></ng-content>

  <div class="m-t-16">
    <ion-item fill="outline" mode="md" class="safe-input" color="secondary">
      <ion-input autocomplete="email" enterkeyhint="next" inputmode="email"
        placeholder="Email" required="true" type="email" [formControl]="emailControl"
        (ionBlur)="checkForEmailErrors()" (ionFocus)="resetEmailErrors()"></ion-input>
      <span slot="error" *ngIf="showEmailRequiredError">Please enter your email.</span>
      <span slot="error" *ngIf="showValidEmailError">Please enter a valid email.</span>
    </ion-item>
  </div>

  <div class="m-t-16" *ngIf="!forgottenPassword">
    <ion-item fill="outline" mode="md" class="safe-input">
      <ion-input autocomplete="current-password" enterkeyhint="go" inputmode="text"
        placeholder="Password" required="true" type="password"
        [formControl]="passwordControl" (keyup.enter)="signIn()"
        (ionBlur)="checkForPasswordErrors()" (ionFocus)="resetPasswordErrors()"></ion-input>
      <span slot="error" *ngIf="showPasswordRequiredError">Please enter your password.</span>
    </ion-item>
  </div>

  <div class="ion-text-center m-t-16" *ngIf="!forgottenPassword">
    <span class="safe-link" (click)="showPasswordReset()">
      <ng-content select="[forgottenPassword]"></ng-content>
    </span>
  </div>

  <div class="ion-text-center m-t-16" *ngIf="forgottenPassword">
    <span class="safe-link" (click)="showSignIn()">
      <ion-text color="secondary">Back to login.</ion-text>
    </span>
  </div>

  <div class="m-t-16 ion-text-center" *ngIf="!forgottenPassword">
    <ion-button *ngIf="!processingSignInWithEmail; else spinner;" class="reset-text-transform button-text"
      type="submit" [disabled]="emailControl.invalid || passwordControl.invalid"
      expand="block" size="large" (click)="signIn()">
      <ng-content select="[signInButtonText]"></ng-content>
    </ion-button>
  </div>

  <div class="m-t-16 ion-text-center" *ngIf="forgottenPassword">
    <ion-button *ngIf="!processingResetEmail; else spinner;" class="reset-text-transform button-text"
      type="submit" [disabled]="emailControl.invalid" expand="block" size="large"
      (click)="sendPasswordResetEmail()">
      <ng-content select="[passwordResetButtonText]"></ng-content>
    </ion-button>
  </div>

  <div class="ion-text-center m-t-16" *ngIf="!forgottenPassword">
    <ion-text color="secondary">Or</ion-text>
  </div>

  <div class="m-t-16 ion-text-center" *ngIf="!forgottenPassword">
    <ion-button *ngIf="!processingSignInWithGoogle; else spinner;" class="reset-text-transform button-text" color="secondary"
      type="button" expand="block" fill="outline" size="large" (click)="signInWithGoogle()">
      <span slot="start" class="google-logo sign-in__google-logo"></span>
      <ion-text class="p-l-12">Sign in with Google</ion-text>
    </ion-button>
  </div>

  <div class="m-t-16 ion-text-center" *ngIf="!forgottenPassword">
    <ion-button *ngIf="!processingSignInWithApple; else spinner;" class="reset-text-transform button-text" color="secondary"
      type="button" expand="block" fill="outline" size="large" (click)="signInWithApple()">
      <span slot="start" class="apple-logo sign-in__apple-logo"></span>
      <ion-text class="p-l-12">Sign in with Apple</ion-text>
    </ion-button>
  </div>

  <ng-template #spinner>
    <ion-spinner class="safe-button-spinner" color="primary"></ion-spinner>
  </ng-template>

  <div *ngIf="error" class="ion-text-center m-t-16">
    <ion-text color="danger">{{error}}</ion-text>
  </div>

  <div *ngIf="success" class="ion-text-center m-t-16">
    <ion-text color="success">{{success}}</ion-text>
  </div>

</form>
